import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import Typewriter from 'typewriter-effect'
import LoopVideo from './assets/loop.mp4'
import Glitch from 'components/glitch'
import Swal from 'sweetalert2'
import ticket from 'sections/images/ticket.gif'
import fondo2 from 'sections/images/fondos/mesa1.jpg'

import Cookies from 'universal-cookie';
import Idioma from 'sections/idiomas/contents.json'
import $ from 'jquery';

class Iniciovideo extends React.Component {

    constructor(props) {
            const cookies = new Cookies();
        super(props)
        let contenido=""
        let continuar_boton=""
        let cancelar_boton=""
        let frase_pie=""
        let url=null;
        let a=cookies.get('idioma')
        if(a=="ingles"){
            contenido=Idioma.ingles.lista_blanca.contenido;
            continuar_boton=Idioma.ingles.lista_blanca.continuar_boton;
            cancelar_boton=Idioma.ingles.lista_blanca.cancelar_boton;
            frase_pie=Idioma.ingles.lista_blanca.frase_pie;
        }else{
            contenido=Idioma.espanol.lista_blanca.contenido;
            continuar_boton=Idioma.espanol.lista_blanca.continuar_boton;
            cancelar_boton=Idioma.espanol.lista_blanca.cancelar_boton;
            frase_pie=Idioma.espanol.lista_blanca.frase_pie;
            
        }

      
        this.state = {
            height: 0,
            width: 0,
            contenido,
            continuar_boton,
            cancelar_boton,
            frase_pie
        }
    }
    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

     maquina(contenedor,texto,intervalo){
        // Calculamos la longitud del texto
       let longitud = texto.length;
        // Obtenemos referencia del div donde se va a alojar el texto.
       let  cnt = document.getElementById(contenedor);
        var i=0;
        // Creamos el timer
       let timer = setInterval(function(){
           // Vamos añadiendo letra por letra y la _ al final.
           cnt.innerHTML = cnt.innerHTML.substr(0,cnt.innerHTML.length-1) + texto.charAt(i) + "_";
           // Si hemos llegado al final del texto..
           if(i >= longitud){
              // Salimos del Timer y quitamos la barra baja (_)
              clearInterval(timer);
              cnt.innerHTML = cnt.innerHTML.substr(0,longitud);
              return true;
           } else {
              // En caso contrario.. seguimos
              i++;
           }},intervalo);
     };

    
      texto = "Los homosapiens han desaparecido en un 99% la sobrepoblación y los problemas de desigualdad hicieron colapsar a la humanidad, fenómenos naturales y pandemias acabaron con estos, dejando así que seres mutados y andrógenos tomaran el control.El planeta ya no es lo que era hace pocos años, hoy está gobernado por reinos, uno de ellos el reino RAZOMBIX donde prevalece una raza de zombie llamada OMBIETAX (SUMIZO) que no tienen capacidad de raciocinio y son dirigidos por superiores, estos ocupan grandes extensiones de tierra. Por otro lado los BIEZEX (COMERCIANTE) se encargan de negociar recursos con los otros reinos y permitir que la raza siga avanzando; existen los DESUX (CIENTÍFICO) un número reducido de ejemplares que tienen una capacidad de raciocinio mayor y están obsesionados con lograr que la longevidad de esta raza sea más larga, puesto que el tiempo de vida...";
    componentDidMount() {
        let el = document.getElementById('swalHTML')
        const cookies = new Cookies();
        let inicio=cookies.get('inicio')
        if(inicio!="1"){
            Swal.fire({
                allowOutsideClick: false,
                title:"<h5 class='text-cyan borde-negro color_fondo_unico py-3'>Planeta Tierra AÑO 2025 </h5>",
                footer:"Cryptoversox",
                html:' <div id="maquinas" class="text-center"></div>',
                width: 700,
                padding: '1em',
                color: '#000000',
                confirmButtonColor:'#11caff',
               
                background: '#fff url('+fondo2+')',
                confirmButtonText: "Siguiente",
               
                backdrop: `
                  rgba(0,0,123,0.4)
                  url(`+ticket+`)
                  left top
                  no-repeat
                `
              }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        title: 'Usuario',
                        html: `
                        <input type="text" id="nombre" class="swal2-input" placeholder="Nombre">
                        <input type="text" id="correo" class="swal2-input" placeholder="Correo">`,
                        confirmButtonText: 'Enviar',
                        focusConfirm: false,
                        preConfirm: () => {
                          const login = Swal.getPopup().querySelector('#nombre').value
                          const password = Swal.getPopup().querySelector('#correo').value
                          if (!login || !password) {
                            Swal.showValidationMessage(`Debe rellenar todos los datos`)
                          }
                          return { login: login, password: password }
                        }
                      }).then((result) => {
                        $.ajax({
                            data:{nombre:result.value.login,correo:result.value.password},
                            type:"POST",
                            url: "https://cryptoversox.com/api_billetera/evento.php"
                        }).then(function(data) {
                            console.log("ok")
                        });
                        Swal.fire('Te invitamos a mirar nuestro sitio')
                       
                        cookies.set('inicio', "1", { path: '/' });
                    
                    })
    
    
                }
              })
    
              this.maquina("maquinas",this.texto,100);
        }
        
          
          
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
        document.body.addEventListener('mousemove', (e) => {
            var amountMovedX = (e.clientX * -.1 / 8);
            var amountMovedY = (e.clientY * -.1 / 8);
            var x = document.getElementsByClassName("parallax-hero-item");
            var i;
            for (i = 0; i < x.length; i++) {
              x[i].style.transform='translate(' + amountMovedX + 'px,' + amountMovedY + 'px)'
            }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {

        const Section = styled.section`
            position: relative;
            .particles {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            background-size: cover;
            background-repeat: no-repeat;
        `

        const VideoContainer = styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            video {
                position: absolute;
            }
            @media (max-width: 1023px) {
                width: 100%;
            }
        `
        const Heading = styled.div`
            .glitch {
                font-size: 110px;
                line-height: 140px;
                font-weight: 600;
                color: #fff;
                @media (max-width: 767px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        `

        

        const SubHeading = styled.h2`
            font-size: 18px;
            font-weight: 300;
            color: #ccc;
            text-transform: uppercase;
            letter-spacing: 4px;
        `
       const Type = styled.div`
            font-size: 50px;
            line-height: 50px;
            color: #000;
            text-transform: uppercase;
            margin-left: 6px;
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 23px;
                line-height: 20px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
            span {
                font-family: Teko;
            }
        `
        const gradientAnimation = keyframes`
            0% {
              background-position: 15% 0%;
            }
            50% {
              background-position: 85% 100%;
            }
            100% {
              background-position: 15% 0%;
            }
          `
          

        const HeadingBox = styled.div`
            height: 500px;
            width: 900px;
            margin: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 5px;
                background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
                background-size: 300% 300%;
                clip-path: polygon(0% 100%, 10px 100%, 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 10px), 10px calc(100% - 10px), 10px 100%, 100% 100%, 100% 0%, 0% 0%);
            }
            &.animate:after {
                animation: ${gradientAnimation} 2s ease-in-out infinite;
            }
            @media (max-width: 767px) {
                height: 350px;
            }
        `

        const Resume = styled.a`
            color: #fff;
            text-decoration: none;
            border-bottom: 2px solid;
            font-weight: 300;
            transition: .5s;
            margin-top: 40px;
            &:hover {
                color: #fff;
                text-decoration: none;
                border-color: #04e5e5;
            }
        `

        return (
            <Section id="home">
                <VideoContainer style={{height: `${this.state.width > 500 ? this.state.height : 350}px`}}>
                    <video autoPlay="autoplay" class="center"  loop="loop" muted style={{height: `${this.state.width >= 1023? "110%": "60%"}`}}>
                        <source src={LoopVideo} type="video/mp4" />
                    </video>
                   
                    {this.shapes()}
           
         
                    <div id="swalHTML" style={{display: 'none'}}>
                    
                            <Typewriter
                                options={{
                                strings: [
                                    'App Developer',
                                    'Web Designer',
                                    'UX Developer',
                                ],
                                autoStart: true,
                                loop: true,
                                }}
                            />
                       
                </div>
            
                     
                </VideoContainer>
            </Section>
        )
    }

    shapes() {

        const MoveUp = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(6px);
            }
        `

        const MoveDown = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(40px);
            }
        `
        const Shape = styled.img`
            position: absolute;
            height: 50px;
            &.move-up {
                animation: ${MoveUp} 5s infinite  alternate;
            }
            &.move-down {
                animation: ${MoveDown} 5s infinite  alternate;
            }
            @media (max-width: 767px) {
                height: 20px;
            }
        `
        


        return this.props.shapes.map((value, index) => {
            return (
                <Shape
                    style={{
                        left: `${(index+1) * 10}%`,
                        bottom: `${Math.random() *
                        (+((index+1) % 2 === 0 ? 10 : 90) - +((index+1) % 2 === 0 ? 5 : 80)) +
                        +((index+1) % 2 === 0 ? 5 : 80)}%`,
                    }}
                    key={index}
                    src={value.node.childImageSharp.fluid.src}
                    alt="shape"
                    className={Math.floor(Math.random() * 10) % 2 === 0 ? "move-up" : "move-down"}
                />
            )
        })
    }
}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        shapes: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "shapes"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
      }    
      `}
      render={({ shapes }) => <Iniciovideo shapes={shapes.edges} {...props} />}
    />
  )