import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Smart from 'components/smart_contract/smart.js'
import Idiomas from 'components/idiomas/idioms.js'
import 'sections/fonts/fuente.css';
import $ from 'jquery';

import Cookies from 'universal-cookie';
import { faGithub, faLinkedin, faTwitter, faYoutube, faInstagramSquare, faDiscord, faPinterestSquare } from '@fortawesome/free-brands-svg-icons'
var scrollToElement = require('scroll-to-element')

class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          collapse: false,
          sticky: false,
          sections: this.props.sections ? this.props.sections : ['home', 'about', 'services', 'portfolio', 'testimonials', 'clients', 'team', 'blog', 'contact']
        }
    }

    componentDidMount() {
       
        const cookies = new Cookies();
        let inicio=cookies.get('contador')
        if(inicio!="1"){
       
            $.ajax({
                data:{},
                type:"POST",
                url: "https://cryptoversox.com/api_billetera/contar.php"
            }).then(function(data) {
                console.log("ok")
            });

            cookies.set('contador', "1", { path: '/' });

        }
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (window.pageYOffset >= 50 && this.state.sticky) {
            if (this.state.collapse !== nextState.collapse) {
                return true
            }
            return false
        } else {
            return true
        }
    }

    handleScroll = event => {
        if (window.pageYOffset >= 50) {
            this.setState({ sticky: true })
        } else {
            this.setState({ sticky: false })
        }
    }

    collapseNav() {
        console.log(this.state, 'col')
        if (!this.state.collapse) {
            this.setState({ collapse: true })
        } else {
            this.setState({ collapse: false })
        }
    }
    

    render() {

        const NavbarWrapper = styled.div`
            position: absolute;
            z-index: 1;
            width: 100%;
            padding: 1px 0;
            background-color: rgb(0,0,0);
            z-index: 100;
            &.sticky {
                position: fixed;
                background-color: rgb(0,0,0);
                padding: 0 0;
                @media (max-width: 500px) {
                    padding: 20px 0;
                }
            }
        `

        const NavbarContainer = styled(Container)`
            display: flex;
            position: relative;
            
            @media (max-width: 500px) {
                display: block;
                padding: 0;
            }
            
        `

        const Nav = styled.nav`
            flex: 0 0 80%;
            max-width: 80%;
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: 500px) {
                flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
                background-color: rgba(0,0,0,.8);
                margin-top: 20px;
                &.hidden_mobile {
                    display: none;
                }
            }
        `

        const LogoWrapper = styled.div`
            flex: 0 0 20%;
            max-width: 20%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 500px) {
                flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
            }
        `

        const Logo = styled.img`
            height: 55px;
            width:450px;
            
            @media (max-width: 1023px) {
                height: 60px;
                width:250px;
              
            }
        `

        const Lenguaje = styled.img`
            height: 45px;
            font-size: 35px;
            color: #fff;
            margin: 0 5px;
            cursor: pointer;
            transition: .5s;
            &:hover {
                color: #04e5e5;
            }
            @media (max-width: 1023px) {
                height: 30px;
            }
        `

        const NavInner = styled.div`
            justify-content: flex-end;
            
        `

        const Toggler = styled.button`
            color: #ff00ff;
            position: absolute;
            right: 0;
            top: 0;

            @media (min-width: 500px) {
                display: none;
            }
        `
        
        const SocialIcons = styled.div`
        .social_icon {
            display: flex;
            font-size: 35px;
            color: #fff;
            margin: 0 5px;
            cursor: pointer;
            transition: .5s;
            &:hover {
                color: #04e5e5;
            }
            @media (max-width: 1023px) {
                display:none;
            }
        }
    `
    const SocialIconsN = styled.div`
    .social_icon {
        display: flex;
        font-size: 35px;
        color: #fff;
        margin: 0 5px;
        cursor: pointer;
        transition: .5s;
        &:hover {
            color: #04e5e5;
        }
      
    }
`
        
        return(
            <NavbarWrapper className={`header${this.state.sticky === true ? ' sticky' : ''}`}>
                <NavbarContainer>
                    
                    <LogoWrapper className="logo">
                    <SocialIcons className='px-2 py-2' >
                   
                    <FontAwesomeIcon icon={faDiscord} className="social_icon" onClick={() => window.open('https://bit.ly/discordcryptoversox')}/>
                    </SocialIcons>
                    <SocialIcons className='px-2 py-2' >
                    <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://twitter.com/cryptoversox')} />
                    </SocialIcons>
                        <Logo src="/img/logo.png" alt="logo"/>
                    </LogoWrapper>
                    <Toggler
                        onClick={() => this.collapseNav()}
                        className="navbar-toggler navbar-toggler-right"
                    >
                        <FontAwesomeIcon icon={faBars} className="bars" />
                    </Toggler>
                    <Nav className={`navbar navbar-expand-sm ${this.state.collapse === true ? 'expand' : 'hidden_mobile'}`}>
                        <NavInner className={`navbar-collapse collapse ${this.state.collapse === true ? 'show' : ''}`}>
                      
                            <div className="navbar-nav" >{this.navItems()}</div>

                            <div className="navbar-nav text-center"><Smart/></div>

                            <div className="navbar-nav text-center py-2"><Idiomas/> </div>
   
                            <div className="navbar-nav text-center py-2" style={{display: this.state.collapse === true ? "":"none"}} >
                            <SocialIconsN className='py-2' style={{display:"inline"}} >
                                <FontAwesomeIcon style={{display:"inline"}} icon={faDiscord} className="social_icon  rounded mx-auto d-block " onClick={() => window.open('https://bit.ly/discordcryptoversox')}/>
                                <FontAwesomeIcon style={{display:"inline"}} icon={faTwitter} className="social_icon rounded mx-auto d-block " onClick={() => window.open('https://twitter.com/cryptoversox')} />
                            </SocialIconsN>
                            
                            </div>
                        </NavInner>
                    </Nav>
                </NavbarContainer>
            
            </NavbarWrapper>
            
        )
    }

    redireccion(name){
        switch (name) {
            case "Home":
                window.location.href = `/`;
                break;
                case "Road Map":
                    window.location.href = `/${"road_map"}`;
                    break;
                    case "Life Paper":
                        window.location.href = `/${"life_paper"}`;
                        break;
                        case "About Us":
                            window.location.href = `/${"sobre_nosotros"}`;
                            break;
                           
            default:
                break;
        }
    }

    navigate(id) {
        if (this.props.scroll) {
           this.redireccion(id)
            const el = document.getElementById(id)
            scrollToElement(el, {
                offset: 0,
                ease: 'in-out-expo',
                duration: 2000
            })
        } else {
            window.location.href = `./#${id}`;
        }
    }

    navItems() {
        const NavItem = styled.button`
        font-size: 18px;
            background: none;
            border: none;
            color: #fff;
            text-transform: capitalize;
            font-weight: 500;
            margin: 10px 5px;
            transition: .5s;
            &:hover {
                color: #fff;
            }
            &:focus {
                outline: none;
            }
            @media (min-width: 501px) and (max-width: 1023px) {
                font-size: 11px;
                margin: 2px;
            }
        `
        
        return this.state.sections.map((value, index) => {
            return (
                <NavItem key={index} onClick={() => this.navigate(value)}>
                    {value}
                </NavItem>
                
            )
        })
    }
}

export default Navbar