import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube, faInstagramSquare, faDiscord, faPinterestSquare } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component{

    render() {
        const FooterMain = styled.div`
            background-color: #000000;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
        `

        const SocialIcons = styled.div`
            .social_icon {
                font-size: 25px;
                color: #fff;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #04e5e5;
                }
            }
        `
        const Logo = styled.img`
        height: 55px;
        
        @media (max-width: 1023px) {
            height: 30px;
        }
    `



        return (

            
            
            <FooterMain className='my-2 '>

                  
                <div className='container-fluid  '>
                    <div className='row align-items-center justify-content-center'>
                        
                        <div className='col-md-4  text-center'>
                            <div className='row justify-content-right'>
                                <div className='col-md-12 text-light'>

                                <SocialIcons className='px-2 justify-content-center' >
                                    <FontAwesomeIcon icon={faDiscord} className="social_icon" onClick={() => window.open('https://bit.ly/discordcryptoversox')}/>
                                    <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://twitter.com/cryptoversox')} />
                                </SocialIcons>
                                </div>
                                <div className='col-md-12 text-light pt-2'>© 2022 CRYPTOVERSOX</div>
                            </div>
                        </div>
                    </div>
                </div>
            

               
            </FooterMain>
        )
    }
}
export default Footer