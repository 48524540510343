import React from 'react'
import Web3 from 'web3';
import handleViewport from 'react-in-viewport'
import styled, { keyframes } from 'styled-components'
import ingles from 'sections/images/estados-unidos.png'
import espana from 'sections/images/espana.png'
import Cookies from 'universal-cookie';
class Idioms extends React.Component {
  constructor(props){
    super(props)
    this.state={
      account:"",
    }
   
  }

  

cambio_idioma(idioma){
    const cookies = new Cookies();
    cookies.remove('idioma')
    if(idioma=="i"){
        cookies.set('idioma', "ingles", { path: '/' });
        window.location.reload();
    }else{
        cookies.set('idioma', "espanol", { path: '/' });
        window.location.reload();
    }
}

    render() {
      const Submit = styled.button`
        
        height: 50px;
        width: 175px;
        position: relative;
        border: none;
        overflow: hidden;
        transition: .5s;
        &::after {
          position: absolute;
          content:'';
          display: inline-block;
          background: rgba(255, 0, 255, 1);
          background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(195 62 195 / 63%) 25%, rgba(255, 0, 255, 1) 51%, rgba(0, 0, 0, 1) 100%);
          height: 100%;
          width:  140%;
          top: 0;
          left: 0;
          transition: .5s;
        }
        
        &:hover {
          &::after {
            transform: translateX(-20%);
          }
        }
        span {
          font-size: 15px;
          position: relative;
          top: 0;
          -webkit-text-stroke: 0.5px black;
          color: #fff;
          z-index: 10;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
    `
    const Lenguaje = styled.img`
    height: 45px;
    font-size: 35px;
    color: #fff;
    margin: 0 5px;
    cursor: pointer;
    transition: .5s;
    &:hover {
        color: #04e5e5;
    }
    @media (max-width: 1023px) {
        height: 30px;
    }
`

  
        return(
          
         
             <small className='text-white pl-2'>
                            <span>
                           <Lenguaje src={ingles}  onClick={()=>this.cambio_idioma("i")}/>
                           <Lenguaje src={espana}  onClick={()=>this.cambio_idioma("e")} />
                            </span>
            </small>
        
         
        )
      }
}
const smart = handleViewport(Idioms);
export default smart