import React from 'react'
import Web3 from 'web3';
import handleViewport from 'react-in-viewport'
import styled, { keyframes } from 'styled-components'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie';
import Idioma from 'sections/idiomas/contents.json'
class Smarts extends React.Component {
  constructor(props){
    const cookies = new Cookies();
    super(props)
    let a=cookies.get('idioma')

    let titulo=""
    let contenido=""
    let descarga=""
      if(a=="ingles"){
         titulo=Idioma.ingles.metamask.titulo;
         contenido=Idioma.ingles.metamask.contenido;
         descarga=Idioma.ingles.metamask.descarga;
    }else{
      titulo=Idioma.espanol.metamask.titulo;
      contenido=Idioma.espanol.metamask.contenido;
      descarga=Idioma.espanol.metamask.descarga;
    }
    this.state={
      account:"",
      tiulos:titulo,
      contenidos:contenido,
      descargas:descarga,
    }
   
  }

  async componentDidMount(){
   /*
    window.web3=new Web3(window.ethereum)
    const web3=window.web3
    //cargar cuentas
    const accounts=await web3.eth.getAccounts();
    console.log(accounts);
    if(accounts.length==0){
      this.setState({account: "CONNECT WALLET"})
    }else{
      this.setState({account: accounts[0]})
    }
    */
  }
  async saludo(){
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
     
      window.open("https://metamask.app.link/dapp/cryptoversox.com/", '_blank');
  }else{
    if(window.ethereum){
      window.web3=new Web3(window.ethereum)
      await window.ethereum.enable()
      const web3=window.web3
      //cargar cuentas
      const accounts=await web3.eth.getAccounts();
      this.setState({account: accounts[0]})
    }else{
      Swal.fire({
        title: "<h4 style='color:ff00ff'>"+this.state.tiulos+"</h4><br><hr style='border-top: 1px solid #ff00ff;'>",
        width: 600,
        padding: '3em',
        color: '#fff',
        text: this.state.contenidos,
        footer: '<a href="https://metamask.io/download/" target="_blank" style="color:ff00ff">'+this.state.descargas+'</a>',
        background: '#000',
        confirmButtonText: 'OK',
        confirmButtonColor: '#ff00ff',
        backdrop: `
          rgba(0,0,0,0.1)
          url("/images/nyan-cat.gif")
          left top
          no-repeat
        `
      })
    }
  }
   
  }



    render() {
      const Submit = styled.button`
        
        height: 50px;
        width: 175px;
        position: relative;
        border: none;
        overflow: hidden;
        transition: .5s;
        &::after {
          position: absolute;
          content:'';
          display: inline-block;
          background: rgba(255, 0, 255, 1);
          background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(195 62 195 / 63%) 25%, rgba(255, 0, 255, 1) 51%, rgba(0, 0, 0, 1) 100%);
          height: 100%;
          width:  140%;
          top: 0;
          left: 0;
          transition: .5s;
        }
        
        &:hover {
          &::after {
            transform: translateX(-20%);
          }
        }
        span {
          font-size: 15px;
          position: relative;
          top: 0;
          -webkit-text-stroke: 0.5px black;
          color: #fff;
          z-index: 10;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
    `
  
        return(
          
          <small className='text-white'>
            <Submit onClick={()=>this.saludo()}><span>
              {this.state.account==""
            ?"Connect":this.state.account
            }
            </span></Submit>
        </small>
         
        )
      }
}
const smart = handleViewport(Smarts);
export default smart